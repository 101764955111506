.navbar-wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1em 10%;
    position: fixed;
    top: 0;
    z-index: 20;
    transition: all 1s linear;

    .navbar-logo {
        width: 3.5em;
        height: 1.5em;
    }

    .icon-bars {
        font-size: 2em;
        color: $color-primary;
        cursor: pointer;
    }

    .icon-x {
        font-size: 2em;
        color: $color-primary;
        cursor: pointer;
        margin-right: .2em;
        position: relative;
        z-index: 2;
    }
}
.form-wrapper {
    .form-content {
        width: 80%;
        height: 100vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2em 0;

        .map-content {
            width: 55%;
            height: 80%;
            position: relative;

            .map-small {
                position: absolute;
                top: 0;
                left: 0;
                width: 10em;
                height: 8em;
                background-color: #343C3F;
                border: 1px solid $color-secundary;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: space-between;
                
                img {
                    width: 70%;
                    height: 80%;
                }
                
                p {
                    color: $color-primary;
                    font-size: .8em;
                }

                .point {
                    width: .4em;
                    height: .4em;
                    position: absolute;
                    top: 1.6em;
                    right: 3.8em;
                    background-color: $color-secundary;
                    border-radius: 50%;
                    box-shadow: 0 0 0 0 rgba($color: $color-secundary, $alpha: 1.0);
                    animation: pulse 1.5s infinite ease;
                    z-index: 5;
                }

                .info-map{

                        img {
                            width: 1em;
                            height: 1em;
                        }
                        
                        p {
                            align-self: flex-end;
                            font-size: .8em;
                        }

                    .iconsContact {
                        width: .9em;
                        height: .9em;
                        color: $color-primary;
                    }
                }
            }

            .ubication-icon {
                position: absolute;
                top: 40%;
                left: 45%;
                transform: translate(-45%, -40%);
                width: 2em;
                height: 3em;
                animation: bounce 1s infinite linear;
            }
            
            .map {
                width: 100%;
                height: 100%;
                object-fit: cover ;
            }
        }

        .form-contact {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            height: 80%;
            width: 35%;

            .warning {
                font-size: 0.8rem;
                color: $color-red;
            }

            .title-section {
                align-self: flex-start;
            }
            
        .btn {
            width: 100%;
        }

        .details {
            width: 100%;
            height: 12em;
            outline: none;
            resize: none;
            background-color: #343C3F;
            caret-color: $color-primary;
            color: $color-primary;
            padding: .2em;
        }

            .inputstyle {
                width: 100%;

                input {
                    font-size: 1.2em;
                    width: 100%;
                    background-color: transparent;
                    color: $color-primary;
                    border-bottom: .2em solid $color-primary;
                }

                #name, #company, #details {
                    text-transform: capitalize;
                }
            }
        }
    }
}

@keyframes bounce {
    from {
        transform: scale3d(1, 1, 1);
    }
    
    30% {
        transform: scale3d(1.25, 0.75, 1);
        top: 42%;
    }
    
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    
    50% {
        transform: scale3d(1.15, 0.85, 1);
        top: 36%;
    }
    
    65% {
        transform: scale3d(.95, 1.05, 1);
    }
    
    75% {
        transform: scale3d(1.05, .95, 1);
    }
    
    to {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($color: $color-secundary, $alpha: .7);
    }
  
    70% {
        transform: scale(1.2);
        box-shadow: 0 0 0 1.2em rgba($color: $color-secundary, $alpha: 0);
    }
  
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($color: $color-secundary, $alpha: 0);
    }
}
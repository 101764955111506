.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: $color-darklight;
    z-index: 19;
    transition: all 0.5s;
    scroll-behavior: smooth;
    
    h2 {
        position: absolute;
        top: 50%;
        left: -.2em;
        transform: translateY(-50%);
        font-size: 15em;
        color: $color-dark;
        opacity: 0.2;
        pointer-events: none;
    }

    .modal-container {
        width: 80%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;

        .navbar-menu {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-evenly;
            align-items: flex-start;

            a {
                font-size: 3em;
                font-weight: 700;
                background-color: $color-primary;
                position: relative;
                text-transform: uppercase;
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
            }
    
            a:before {
                content: attr(data-text);
                background-color: $color-secundary;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                transition: all .2s linear;
                background-clip: text;
                -webkit-background-clip: text;
            }
    
            a:hover:before {
                width: 100%;
            }
    
        }
    }
}
.app {
	width: 100vw;
	height: 100vh;
	position: relative;

    .sections {

        > *{
			width: 100%;
			height: 100vh;
        }
    }
}

@media screen and (max-width: 500px){
	.modal {
		.modal-container {
			align-items: center;

			.navbar-menu {
				align-items: center;

				a {
					font-size: 2.5em;
				}
			}
		}
	}

	.navbar-wrapper {

		.icon-bars {
			font-size: 1.7em;
		}
		
		.icon-x {
			font-size: 1.7em;
			
		}
	}
}

@media screen and (max-width: 300px){ 
	.modal {
		.modal-container {
			.navbar-menu {
				a {
					font-size: 2em;
				}
			}
		}
	}

	.navbar-wrapper {

		.icon-bars {
			font-size: 1.5em;
		}
		
		.icon-x {
			font-size: 1.5em;
			
		}
	}
}

@media screen and (max-width: 200px){ 
	.modal {
		.modal-container {
			.navbar-menu {
				a {
					font-size: 1.5em;
				}
			}
		}
	}

	.navbar-wrapper {

		.icon-bars {
			font-size: 1.2em;
		}
		
		.icon-x {
			font-size: 1.2em;
			
		}
	}
}

@media screen and (max-width: 150px){ 
	.modal {
		.modal-container {
			.navbar-menu {
				a {
					font-size: 1em;
				}
			}
		}
	}
}

@media screen and (max-width: 100px){ 
	.modal {
		.modal-container {
			.navbar-menu {
				a {
					font-size: .65em;
				}
			}
		}
	}

	.navbar-wrapper {

		.icon-bars {
			font-size: .9em;
		}
		
		.icon-x {
			font-size: .9em;
			
		}
	}
}
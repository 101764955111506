.button-top {
    position: fixed;
    width: 3em;
    height: 3em;
    bottom: 2em;
    right: 2em;
    z-index: 18;
    cursor: pointer;
    background-color: $color-primary;
    border-radius: 50%;
    border: 1px $color-secundary solid;
    
    .arrow-up {
        font-size: 2.5em;
        color: $color-secundary;
    }
}
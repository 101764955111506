.about-wrapper {

    .about-content {
        width: 80%;
        height: 100vh;
        display: flex;

        .about {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-flow: column nowrap;

            h2 {
                position: relative;
            }

            h2:before {
                content: '<div>';
                font-family: 'La Belle Aurore', cursive;
                color: rgba($color: #EF0D33, $alpha: .4);
                font-size: 1.5rem;
                position: absolute;
                top: -2em;
                left: 0em;
            }

            p {
                font-size: 1.5em;
                color: $color-primary;
                position: relative;
            }

            p:after {
                content: '<div/>';
                font-family: 'La Belle Aurore', cursive;
                color: rgba($color: #EF0D33, $alpha: .4);
                font-size: 1.5rem;
                position: absolute;
                bottom: -2em;
                right: 0em;
            }
        }

        .about-img {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .bg-img {
                display: flex;
                justify-content: center;
                background-color: $color-primary;
                padding: 1em 1em 0;
                border-radius: .5em;
                position: relative;
                
                .photo {
					overflow: hidden;
                    width: 16em;
                    height: 25em;
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
}
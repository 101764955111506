@media screen and (max-width: 1000px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 15rem;
				height: 4.5rem;
			}

			h1 {
				span {
					font-size: 4.5rem;
				}
			}
		}
	}

	.btn {
		width: 15rem;
		height: 4.5rem;

		span {
			font-size: 1.6em;
		}
	}

	.about-wrapper {
		.about-content {
			justify-content: center;

			.about {
				width: 65%;

				p {
					font-size: 1.3em;
				}
			}

			.about-img {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 800px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 14rem;
				height: 4rem;
			}

			h1 {
				span {
					font-size: 4rem;
				}
			}
		}
	}

	.btn {
		width: 14rem;
		height: 4rem;

		span {
			font-size: 1.5em;
		}
	}

	.about-wrapper {
		.about-content {
			.about {
				width: 70%;

				p {
					font-size: 1.1em;
				}
			}
		}
	}

	.title-section {
		font-size: 2.7rem;
	}

	.form-wrapper {
		.form-content {
			justify-content: center;
			.map-content {
				display: none;
			}

			.form-contact {
				width: 55%;
			}
		}
	}
}

@media screen and (max-width: 720px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 13rem;
				height: 3.7rem;
			}

			h1 {
				span {
					font-size: 3.5rem;
				}
			}

			h1:before, h1:after {
				font-size: 1.2rem;
			}
		}
	}

	.btn {
		width: 13rem;
		height: 3.7rem;

		span {
			font-size: 1.35em;
		}
	}

	.about-wrapper {
		.about-content {
			justify-content: center;

			.about {
				width: 75%;

				h2:before, p:after {
					font-size: 1.2rem;
				}

				p {
					font-size: 0.9em;
				}
			}
		}
	}

	.title-section {
		font-size: 2.4rem;
	}

	.sections {
		.skills-wrapper {
			.skills-content {
				.skills {
					display: flex;
					flex-flow: column wrap;
					justify-content: space-around;

					.skill {
						width: 100%;
					}
				}
			}
		}
	}

	.bar {
		width: 8em;
	}
}

@media screen and (max-width: 610px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 11rem;
				height: 3rem;
			}

			h1 {
				span {
					font-size: 3rem;
				}
			}
		}
	}

	.btn {
		width: 11rem;
		height: 3rem;

		span {
			font-size: 1.1em;
		}
	}

	.about-wrapper {
		.about-content {
			.about {
				width: 80%;
				p {
					font-size: 0.7em;
				}
			}
		}
	}

	.social-wrapper {
		font-size: 13px;
	}

	.button-top {
		font-size: 13px;
	}

	.skills-wrapper {
		font-size: 13px;
	}
}

@media screen and (max-width: 510px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 9rem;
				height: 2.4rem;
			}

			h1 {
				span {
					font-size: 2.5rem;
				}
			}

			h1:before, h1:after {
				font-size: 1rem;
			}
		}
	}

	.btn {
		width: 9rem;
		height: 2.4rem;

		span {
			font-size: 1em;
		}
	}

	.about-wrapper {
		.about-content {
			.about {
				width: 85%;

				h2:before, p:after {
					font-size: 1rem;
				}

				p {
					font-size: 0.6em;
				}
			}
		}
	}

	.skills-wrapper {
		font-size: 12px;
	}

	.title-section {
		font-size: 2rem;
	}

	.form-wrapper {
		.form-content {
			.form-contact {
				justify-content: center;
				gap: 1em;
			}
		}
	}

	.bar {
		width: 6em;
	}
}

@media screen and (max-width: 410px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 7rem;
				height: 2rem;
			}

			h1 {
				span {
					font-size: 1.9rem;
				}
			}

			h1:before, h1:after {
				font-size: .8rem;
			}
		}
	}

	.btn {
		width: 7rem;
		height: 2rem;

		span {
			font-size: 0.7em;
		}
	}

	.about-wrapper {
		.about-content {
			.about {
				width: 70%;
				h2:before, p:after {
					font-size: .8rem;
				}
			}
		}
	}

	.form-wrapper {
		.form-content {
			.form-contact {
				gap: .7em;

				.inputstyle {
					input {
						font-size: 1em;
					}
				}
			}
		}
	}

	.skills-wrapper {
		font-size: 10px;
	}

	.title-section {
		font-size: 1.7rem;
	}

	.bar {
		width: 4em;
	}

	.social-wrapper {
		font-size: 10px;
	}

	.button-top {
		font-size: 10px;
	}

	.footer-wrapper {
			.footer {
				font-size: .8em;
		}
	}
}

@media screen and (max-width: 310px){
	.banner-wrapper {
		.banner-content {
			.idiom {
				width: 5rem;
				height: 1.5rem;

				a {
					width: 1em;
					height: .6em;
				}
			}

			h1 {
				span {
					font-size: 1rem;
				}
			}

			h1:before, h1:after {
				font-size: .5rem;
			}
		}
	}

	.btn {
		width: 5rem;
		height: 1.5rem;

		span {
			font-size: 0.5em;
		}
	}

	.about-wrapper {
		.about-content {
			.about {
				width: 90%;
				p{
					font-size: .45em;
				}

				h2:before, p:after {
					font-size: .5rem;
				}
			}
		}
	}

	.form-wrapper {
		.form-content {
			.form-contact {

				.warning {
					font-size: 0.6em;
				}

				.inputstyle {

					input {
						font-size: .8em;
					}

					label {
						top: .9em;
						font-size: .6em;
					}
				}
			}
		}
	}

	.title-section {
		font-size: 1rem;
	}

	.bar {
		width: 3em;
	}

	.social-wrapper {
		font-size: 8px;
	}

	.button-top {
		font-size: 8px;
	}

	.skills-wrapper {
		.skills-content {
			font-size: 8px;
			.skills {
				width: 80% !important;
			}
		}
	}

	.footer-wrapper {
		.footer-content {
			h3 {
				font-size: .6em;
			}
		}

		.footer {
			font-size: .6em;
		}
	}
}

@media screen and (max-width: 210px){
	.banner-wrapper {
		.banner-content {
			justify-content: space-around;
		}
	}

	.form-wrapper {
		.form-content {
			.form-contact {

				.warning {
					font-size: 0.4em;
				}

				.inputstyle {

					input {
						font-size: .6em;
					}
				}

				.details {
					height: 9em;
					font-size: .6em;
				}
			}
		}
	}
}
$color-primary: #fff;
$color-secundary: #EF0D33;
$color-dark: #181B1D;
$color-darklight: #343C3F;
$color-red: #af1932;

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-color: $color-darklight;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

.dark {
    background-color: $color-dark;
}

.light {
    background-color: $color-darklight;
}

*{
    margin: 0;
    padding: 0;
    font-family: "Open Sans",sans-serif;
}

a {
    text-decoration: none;
    outline-style: none;
}

a:visited, a:link, a:active {
    color: $color-dark;
}

input[type=text],input[type=email] {
    outline-style: none;
    border: none;
}

input[type=textarea] {
    max-width: 100%;
}

input[type=textarea]:focus {
    border: 1px solid red;
}

::-webkit-scrollbar {
    width: .7em;
    background-color: $color-darklight;
}

::-webkit-scrollbar-thumb {
    background-color: $color-secundary;
    border-radius: 3em;
  }

.medium {
    width: 8em;
    height: 2.5em;
}

.large {
    width: 9em;
    height: 2em;
}

.btn {
    width: 17rem;
    height: 5rem;
    cursor: pointer;
    border-radius: .2rem;
    border: none;
    position: relative;
    z-index: 1;
    transition: all .2s linear;
    overflow: hidden;

    span {
        font-size: 1.8em;
        transition: all .2s linear;
    }

    span:nth-child(1) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    span:nth-child(2) {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: rgb(43, 145, 85);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-110%);
        cursor: auto;
    }
}

.title-section {
    font-size: 3rem;
    color: $color-secundary;
}

.red {
    background-color: $color-secundary;
    color: $color-primary;
}

.bar {
    width: 10em;
    height: .2em;
    background-color: $color-primary;
    margin: 1em 0;
}

.center {
    display: flex;
	justify-content: center;
	align-items: center;
}

.overlay {
    background-color: rgba(161, 161, 161, 0.8);
    background-blend-mode: overlay;
}
.skills-wrapper {
	overflow-y: hidden;

	.skills-content {
		width: 80%;
		height: 100%;
		padding: 2em 0;
		display: flex;
		flex-flow: column nowrap;
	
		.skills {
			width: 100%;
			height: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(3, 1fr);
			grid-auto-flow: column;
			column-gap: 4em;
			align-items: center;

			.skill {
				height: 3em;
				display: flex;
				flex-wrap: nowrap;
				gap: 1em;
				text-transform: capitalize;

				.skill-img {
					width: 3em;
					height: 100%;
					justify-self: center;
					align-self: flex-end;
				}

				.skill-info {
					width: 100%;
					height: 100%;
					display: flex;
					flex-flow: column nowrap;

					.skill-details {
						width: 100%;
						height: 50%;
						display: flex;
						flex-wrap: nowrap;
						align-items: flex-end;
						color: $color-primary;
						font-size: 1.4em;

						.skill-name {
							width: 80%;
						}

						.skill-percent {
							width: 20%;
							display: flex;
							justify-content: flex-end;
							align-items: flex-end;

							:nth-child(1) {
								color: $color-secundary;
								font-weight: 600;
								letter-spacing: 0.15em;
							}

							:nth-child(2) {
								font-size: .9em;
							}
						}
					}

					.skill-bar {
						width: 100%;
						height: 50%;
						display: flex;
						align-items: flex-end;

						.skill-progress-bar {
							width: 100%;
							height: 60%;
							background-color: white;
							border-radius: .8em;
							filter: drop-shadow(0 0 .5em $color-dark);

							.progress-bar {
								width: 0%;
								height: 100%;
								background: linear-gradient(90deg, rgba(175,25,50,1) 50%, rgba(239,13,51,1) 100%);
								border-radius: .8em;
								transition: all 1s;
							}
						}
					}
				}
			}
		}
	}
}
.social-wrapper {
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 18;

    .social-content {
        width: 3em;
        display: flex;
        flex-flow: column nowrap;
        gap: .8em;
        transform: translateX(.5em);

        .social {
            display: flex;
            width: 100%;
            height: 2.5em;
            transition: all .1s linear;

            a {
                width: 2.5em;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-primary;
                transition: all .1s linear;
                border-radius: 10% 0 0 10%;

                .icon-social {
                    font-size: 1.3em;
                }
            }
            
            .stick-box {
                width: .5em;
                height: 100%;
                display: flex;
                align-items: center;
            }

            .stick {
                width: 100%;
                height: .8em;
                background-color: $color-primary;
            }
        }

        .social:hover {

            transform: translateX(-.5em);
            
            a {
                border-radius: 50%;
                transition: all .1s linear;
                
                div {
                    
                    .icon-social {
                        transition: all .3s linear;
                        transform: rotateZ(-360deg);
                    }
                }
            }
        }
    }
}
.banner-wrapper {

    //Video background
    .bg-video {
        width: 100%;
        height: 100%;
        position: absolute;

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
        
        .bgvideo-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba($color: #343C3F, $alpha: .3);
        }
    }
    
    .banner-content {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: flex-start;

        h1 {
            color: $color-primary;
            position: relative;
            
            span {
                font-size: 5rem;
            }
            
            span:hover {
                color: $color-secundary;
            }
        }

        .idiom {
            width: 17rem;
            height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $color-darklight;
            transition: all .2s linear;
            position: relative;
            z-index: 1;
            border-radius: .2rem;
            border: none;

            a {
                width: 2em;
                height: 1.2em;
                margin: 0 0.5em;

                &:nth-child(1) {
                    background-image: url("https://i.imgur.com/LmvaYxM.jpg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                &:nth-child(2) {
                    background-image: url("https://i.imgur.com/H7BDGxO.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                div {
                    width: 100%;
                    height: 100%;
                }
            }
            
        }
        button {


            span {
                width: 100%;
            }
        }
    
        h1:before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: rgba($color: #EF0D33, $alpha: .4);
            font-size: 1.5rem;
            position: absolute;
            top: -1em;
            left: 0em;
        }
        
        h1:after {
            content: '<h1/>';
            font-family: 'La Belle Aurore', cursive;
            color: rgba($color: #EF0D33, $alpha: .4);
            font-size: 1.5rem;
            position: absolute;
            bottom: -2em;
            right: 0em;
        }
    }
}